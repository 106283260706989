import React from "react"
import { Component } from "react";
import Navbar from './../components/Navbar/Navbar'

class Header extends Component {
 

  render(){
      

    return <Navbar />
         
  }
}




export default Header
