import { Link } from "react-scroll"
import PropTypes from "prop-types"
import React from "react"
import { Component } from "react"
import logo1 from './../../images/logodark.webp'
import logo2 from './../../images/logolight.webp'
import styles from './navbar.module.css'

class Header extends Component {

  state = {
    background: styles.back,
    linkColor: 'dark',
    logo: logo1
  }



  listenScrollEvent = e => {
    if (window.scrollY > 750) {
      this.setState(
        {
          background: styles.afterscroll,
          linkColor: 'white',
          logo: logo2
        }
      )
    } else {
      this.setState(
        {
          background: styles.back,
          linkColor: 'dark',
          logo: logo1
        }
      )
    }
  }


  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  render() {
    return (
      <div className={`${styles.cont}`}>
        <div className={`navbar navbar-expand-lg navbar-light ${this.state.background} fixed-top`}>
          <div className={`container`}>
            <div className="pt-3">
              <Link className={`navbar-brand text-${this.state.linkColor} ${styles.link}`} to="main" smooth={true} duration={1000} ><img style={{ height: '4vh' }} alt="logoimage" src={this.state.logo} /></Link>
            </div>
            <button className="  text-white navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className=" text-white navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ml-auto">
                <Link className={`nav-item nav-link px-4 text-${this.state.linkColor} ${styles.link}`} to="main" smooth={true} duration={1000}>Home <span className="sr-only">(current)</span></Link>
                <Link className={`nav-item nav-link px-4 text-${this.state.linkColor} ${styles.link}`} to="about" smooth={true} duration={1000}>About</Link>
                <Link className={`nav-item nav-link px-4 text-${this.state.linkColor} ${styles.link}`} to="services" smooth={true} duration={1000}>Services</Link>
                <Link className={`nav-item nav-link px-4 text-${this.state.linkColor} ${styles.link}`} to="portfolio" smooth={true} duration={1000}>Portfolio</Link>
                {/*<Link className={`nav-item nav-link px-4 text-${this.state.linkColor} ${styles.link}`} to="client" smooth={true} duration={1000}>Clients</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}




export default Header
